/** @jsxImportSource @emotion/react */
import { EuiText, EuiCallOut, EuiTreeView } from "@inscopix/ideas-eui";
import {
  ToolParamsGridRowDatum,
  ToolCellStatusParam,
} from "../ToolParamsGrid.types";
import { ProjectFile } from "stores/project-files/ProjectFilesManager";
import { useToolParamsGridContext } from "../ToolParamsGridProvider";
import { isToolPathParam } from "../ToolParamsGrid.helpers";
import { captureException } from "@sentry/react";
import { CallOutError } from "components/CallOutError/CallOutError";
import { useState } from "react";
import { useGetProjectFilesByFileIds } from "../hooks/useGetProjectFilesByFileIds";
import { css } from "@emotion/react";
import { useToolParamsGridRowDataContext } from "../ToolParamsGridRowDataProvider";
import { CellStatusEditorProps } from "components/CellStatusEditor/CellStatusEditor";
import { CellStatuses } from "components/CellStatusEditor/CellStatusEditor.types";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { ModalToolCellStatusParamWrapper } from "./ModalToolCellStatusParamWrapper";
import { ModalToolCellStatusParamWithFile } from "./ModalToolCellStatusParamWithFile";
import { ModalToolCellStatusParamNoSourceFile } from "./ModalToolCellStatusParamNoSourceFile";
import { useProjectPermissionStore } from "pages/project/ProjectPermissionProvider";

export type ModalToolCellStatusParamProps = {
  toolParam: ToolCellStatusParam;
  rowId: ToolParamsGridRowDatum["id"];
  initialCellStatuses?: CellStatuses;
  onClose: () => void;
} & Pick<CellStatusEditorProps, "isInvalidInitialCellStatuses">;

export const ModalToolCellStatusParam = ({
  rowId,
  toolParam,
  initialCellStatuses,
  isInvalidInitialCellStatuses,
  onClose,
}: ModalToolCellStatusParamProps) => {
  const data = useToolParamsGridRowDataContext((s) =>
    s.rowData.find((row) => row.id === rowId),
  );
  assert(
    data !== undefined,
    "Expected row data to be defined in GridRendererToolCellStatusParam",
  );

  const { getProjectFilesByFileIds } = useGetProjectFilesByFileIds();
  const [selectedSourceFile, setSelectedSourceFile] =
    useState<Pick<ProjectFile, "id">>();

  const hasEditPermission = useProjectPermissionStore(
    (s) => s.permissions["edit"],
  );
  const readOnly = data.task_id !== undefined || !hasEditPermission;

  const { toolSpec } = useToolParamsGridContext();
  const updateRowDatum = useToolParamsGridRowDataContext(
    (s) => s.updateRowDatum,
  );

  const cellSetFileParamKey = toolParam.type.source_files.find(
    (sourceFile) => sourceFile.data?.type === "cell_set",
  )?.key;
  assert(
    cellSetFileParamKey !== undefined,
    "Cell set source file parameter key must be set for cell status parameter",
  );
  const cellSetFileParam = toolSpec.params.find(
    (param) => param.key === cellSetFileParamKey,
  );
  assert(
    cellSetFileParam !== undefined,
    `Could not find matching source parameter for provided key ${cellSetFileParamKey}`,
  );
  // there must be a matching source file parameter for the cell set
  if (cellSetFileParam === undefined) {
    captureException(
      `Could not find matching source parameter for provided key`,
      {
        extra: {
          cellSetFileParam,
        },
      },
    );
    return (
      <ModalToolCellStatusParamWrapper toolParam={toolParam} onClose={onClose}>
        <EuiCallOut
          title="No cell set file found"
          color="primary"
          iconType="warning"
        >
          <EuiText>
            <p>Select a valid cell set input file to edit cell statuses</p>
          </EuiText>
        </EuiCallOut>
      </ModalToolCellStatusParamWrapper>
    );
  }

  if (!isToolPathParam(cellSetFileParam)) {
    captureException("Unexpected param type for cell status source file", {
      extra: {
        cellSetFileParam,
      },
    });
    return (
      <ModalToolCellStatusParamWrapper toolParam={toolParam} onClose={onClose}>
        <CallOutError />
      </ModalToolCellStatusParamWrapper>
    );
  }

  // QC report source file is optional
  const qcReportFileParamKey = toolParam.type.source_files.find(
    (sourceFile) => sourceFile.data?.type === "calcium_image_qc_report",
  )?.key;
  const qcReportFileParam = toolSpec.params.find(
    (param) => param.key === qcReportFileParamKey,
  );

  if (qcReportFileParam && !isToolPathParam(qcReportFileParam)) {
    captureException("Unexpected param type for QC report source file", {
      extra: {
        qcReportFileParam,
      },
    });
  }

  // event set source file is optional
  const eventSetFileParamKey = toolParam.type.source_files.find(
    (sourceFile) => sourceFile.data?.type === "neural_events",
  )?.key;
  const eventSetFileParam = toolSpec.params.find(
    (param) => param.key === eventSetFileParamKey,
  );

  if (eventSetFileParam && !isToolPathParam(eventSetFileParam)) {
    captureException("Unexpected param type for event set source file", {
      extra: {
        eventSetFileParam,
      },
    });
  }

  const cellSetFilesFromFileIds = getProjectFilesByFileIds(
    (data.params[cellSetFileParamKey] ?? []) as string[],
  );

  const cellSetFiles = cellSetFilesFromFileIds.drsFilesFound;

  const qcReportFilesFromFileIds = isDefined(qcReportFileParamKey)
    ? getProjectFilesByFileIds(
        (data.params[qcReportFileParamKey] ?? []) as string[],
      )
    : undefined;

  const qcReportFiles = qcReportFilesFromFileIds?.drsFilesFound;

  const eventSetFilesFromFileIds = isDefined(eventSetFileParamKey)
    ? getProjectFilesByFileIds(
        (data.params[eventSetFileParamKey] ?? []) as string[],
      )
    : undefined;

  const eventSetFiles = eventSetFilesFromFileIds?.drsFilesFound;

  /**c
   * None of the selected files could be found (i.e. all have been deleted after they were selected)
   */
  const isFileNotFound =
    cellSetFiles.length === 0 &&
    cellSetFilesFromFileIds.fileIdsNotFound.length > 0;

  if (isFileNotFound) {
    return (
      <ModalToolCellStatusParamWrapper toolParam={toolParam} onClose={onClose}>
        <EuiCallOut>
          <EuiText>
            <p>File not found.</p>
            <p>
              The selected cell set file could not be located. It may have been
              deleted.{" "}
            </p>
          </EuiText>
        </EuiCallOut>
      </ModalToolCellStatusParamWrapper>
    );
  }

  /**
   * No file selected
   */

  if (cellSetFiles.length === 0) {
    return (
      <ModalToolCellStatusParamNoSourceFile
        toolParam={toolParam}
        rowDatum={data}
        sourceFileParam={cellSetFileParam}
        onClose={onClose}
        readOnly={readOnly}
      />
    );
  }

  const onAccept = (statuses: CellStatuses) => {
    const newParamValue = JSON.stringify(statuses);

    updateRowDatum(data.id, {
      params: {
        [toolParam.key]: newParamValue,
      },
    });
    onClose();
  };

  /**
   * Auto pick if only one file
   */
  if (
    cellSetFiles.length === 1 &&
    !cellSetFiles[0].isSeries &&
    selectedSourceFile === undefined
  ) {
    setSelectedSourceFile(cellSetFiles[0]);
  }

  /**
   * Auto pick if only one series file
   * we just need cell names and statuses, should be the same for all series files
   * so we can auto-pick the first one
   */
  if (
    cellSetFiles.length === 1 &&
    cellSetFiles[0].isSeries &&
    cellSetFiles[0].seriesFiles.length > 0 &&
    selectedSourceFile === undefined
  ) {
    setSelectedSourceFile(cellSetFiles[0].seriesFiles[0]);
  }

  /**
   * When selecting from a file list - auto-pick the first child if it's a series
   */
  const onSelectFile = (drsFile: ProjectFile) => {
    if (drsFile.isSeries) {
      setSelectedSourceFile(drsFile.seriesFiles[0]);
    } else {
      setSelectedSourceFile(drsFile);
    }
  };

  /**
   * Multiple valid files, allow user to select one
   */
  if (selectedSourceFile === undefined) {
    return (
      <ModalToolCellStatusParamWrapper toolParam={toolParam} onClose={onClose}>
        <>
          <EuiText>
            Select which input file you would like to use in the cell set
            editor.
          </EuiText>
          <br />
          <EuiTreeView
            style={{
              border: "1px solid lightgrey",
              borderRadius: 5,
              padding: 10,
            }}
            css={css`
              .euiTreeView__nodeLabel {
                width: 100%;
              }
            `}
            aria-label="File tree"
            showExpansionArrows
            expandByDefault
            items={cellSetFiles.map((file) => ({
              id: file.id,
              label: (
                <EuiText onClick={() => onSelectFile(file)} size="s">
                  {file.name}
                </EuiText>
              ),
            }))}
          />
        </>
      </ModalToolCellStatusParamWrapper>
    );
  }

  return (
    <ModalToolCellStatusParamWrapper
      toolParam={toolParam}
      onClose={onClose}
      readOnly={readOnly}
      fullScreen
    >
      <ModalToolCellStatusParamWithFile
        onCancel={onClose}
        cellSetFile={selectedSourceFile}
        qcReportFile={qcReportFiles?.[0]}
        eventSetFile={eventSetFiles?.[0]}
        onAcceptStatuses={onAccept}
        readOnly={readOnly}
        initialCellStatuses={initialCellStatuses}
        isInvalidInitialCellStatuses={isInvalidInitialCellStatuses}
      />
    </ModalToolCellStatusParamWrapper>
  );
};
