import {
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuItemIcon,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
} from "@inscopix/ideas-eui";
import assert from "assert";
import ModalDeleteProject from "components/ModalDeleteProject/ModalDeleteProject";
import { ModalProjectSharing } from "components/ModalProjectSharing/ModalProjectSharing";
import { permissions } from "components/ModalProjectSharing/permissions";
import { getAdminProjectDjango } from "django/libraryProjectDjango";
import { projectScheduleDeleteAdminManageDjango } from "hooks/useProjectSheduleDeleteDjango/useProjectSheduleDeleteDjango";
import { useModalContext } from "providers/ModalProvider/ModalProvider";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { memo, useState } from "react";
import { USER_ACCESS_LEVELS_BY_KEY } from "types/UserAccessLevels";
import { ProjectsGridCellRendererParams } from "./ProjectsGrid";

export type ProjectsGridCellRendererActionsProps =
  ProjectsGridCellRendererParams;
export const ProjectsGridCellRendererActions = memo(
  function ProjectsGridCellRendererActions({
    data,
  }: ProjectsGridCellRendererActionsProps) {
    assert(
      data !== undefined,
      "Missing row data in ProjectsGridCellRendererActions",
    );
    const currentUserTenants = useUserContext((s) => s.tenants);

    const projectOrgKey = data?.tenantKey;
    const isOrgAdmin =
      currentUserTenants.find((tenant) => tenant.key === projectOrgKey)?.role
        .key === "admin";

    const [popoverOpen, setPopoverOpen] = useState(false);

    const { openModal, closeModal } = useModalContext();

    const togglePopover = () => {
      setPopoverOpen((prev) => !prev);
    };

    const hasAdminAccessExplicitlyGranted =
      data.defaultUserAccessLevel !== USER_ACCESS_LEVELS_BY_KEY["ADMIN"].id;

    type Actions = "delete" | "manage_access";
    const actions: {
      key: Actions;
      adminAction?: boolean;
      text: string;
      icon: EuiContextMenuItemIcon;
      action: () => void;
    }[] = [
      {
        key: "delete",
        text: "Delete",
        icon: "trash",
        action: () =>
          openModal(
            <ModalDeleteProject
              deleteProject={projectScheduleDeleteAdminManageDjango}
              projectId={data.id}
              onClose={closeModal}
            />,
          ),
        adminAction: !hasAdminAccessExplicitlyGranted,
      },
      {
        key: "manage_access",
        text: "Modify Access",
        icon: "users",
        action: () =>
          openModal(
            <ModalProjectSharing
              onClose={closeModal}
              projectId={data.id}
              getProject={getAdminProjectDjango}
              updateInternalDefaultAccessLevel={
                permissions.adminFunctions.internalAccess
                  .updateInternalDefaultAccessLevel
              }
              sendExternalProjectAccessInvitation={
                permissions.adminFunctions.externalAccess
                  .sendExternalProjectAccessInvitation
              }
              resendExternalProjectAccessInvitation={
                permissions.adminFunctions.externalAccess
                  .resendExternalProjectAccessInvitation
              }
              updateExternalProjectSharingInvitation={
                permissions.adminFunctions.externalAccess
                  .updateExternalProjectSharingInvitation
              }
              revokeExternalProjectAccessInvitation={
                permissions.adminFunctions.externalAccess
                  .revokeExternalProjectAccessInvitation
              }
              getInternalUsersWithProjectAccess={
                permissions.adminFunctions.internalAccess
                  .getInternalUsersWithProjectAccess
              }
              createInternalUserAccess={
                permissions.adminFunctions.internalAccess
                  .createInternalUserAccess
              }
              revokeInternalUserAccess={
                permissions.adminFunctions.internalAccess
                  .revokeInternalUserAccess
              }
              getProjectSharingExternalInvitations={
                permissions.adminFunctions.externalAccess
                  .getProjectSharingExternalInvitations
              }
              updateInternalUserAccessLevel={
                permissions.adminFunctions.internalAccess
                  .updateInternalUserAccessLevel
              }
            />,
          ),
        adminAction: !hasAdminAccessExplicitlyGranted,
      },
    ];

    return (
      <EuiFlexGroup justifyContent="center" style={{ width: "100%" }}>
        <EuiFlexItem>
          <EuiPopover
            button={
              <EuiButtonIcon
                aria-label="Actions"
                iconType="gear"
                size="s"
                color="text"
                onClick={togglePopover}
              />
            }
            isOpen={popoverOpen}
            closePopover={() => setPopoverOpen(false)}
            panelPaddingSize="none"
            anchorPosition="rightDown"
          >
            <EuiContextMenuPanel
              items={actions
                .filter(({ adminAction }) => {
                  /**
                   * Filter out org admin actions for non admins
                   */
                  if (adminAction && !isOrgAdmin) {
                    return false;
                  }
                  return true;
                })
                .map(({ key, text, action, icon }) => (
                  <EuiContextMenuItem
                    key={key}
                    icon={icon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setPopoverOpen(false);
                      action();
                    }}
                  >
                    {text}
                  </EuiContextMenuItem>
                ))}
            />
          </EuiPopover>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  },
);
