import { useQuery } from "@tanstack/react-query";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useCallback } from "react";

import { queryKeys } from "django/queryKeys";
import { getAdminProjectsDjango } from "django/libraryProjectDjango";

export const useAdminProjects = () => {
  const tenantKey = useTenantContext((s) => s.currentTenant.key);

  const queryFn = useCallback(async () => {
    const data = await getAdminProjectsDjango();

    return data.map((project) => ({
      ...project,
      dateCreated: project.date_created,
      dateLastActive: project.date_last_active,
      activeStorageSize: project.active_storage_size,
      archivedStorageSize: project.archived_storage_size,
      usedCredits: project.used_credits,
      role_display: project.permissions.role_display,
      userId: project.user.id,
      defaultUserAccessLevel: project.default_user_access_level,
      userPermissions: project.permissions,
      tenantKey,
    }));
  }, [tenantKey]);

  const query = useQuery({
    queryFn: queryFn,
    queryKey: queryKeys.getAdminProjects,
    refetchOnMount: "always",
  });

  return query;
};
