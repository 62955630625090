/** @jsxImportSource @emotion/react */
import {
  EuiButtonIcon,
  EuiButtonIconProps,
  EuiPopover,
  EuiPopoverProps,
} from "@inscopix/ideas-eui";
import { Tooltip } from "components/Tooltip/Tooltip";
import { Project } from "graphql/_Types";
import { useState } from "react";
import { ButtonProjectInfoBody } from "./ButtonProjectInfoBody";

export interface ButtonProjectInfoProps
  extends Pick<EuiButtonIconProps, "iconSize" | "css" | "color">,
    Pick<EuiPopoverProps, "anchorPosition"> {
  projectId: Project["id"];
}

export const ButtonProjectInfo = ({
  projectId,
  anchorPosition,
  ...props
}: ButtonProjectInfoProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tooltip content="View Project Info">
      <EuiPopover
        title=""
        button={
          <EuiButtonIcon
            aria-label="Project info"
            iconType="iInCircle"
            color="primary"
            iconSize="l"
            {...props}
          />
        }
        isOpen={isOpen}
        closePopover={() => setIsOpen(false)}
        onClick={() => setIsOpen((isOpen) => !isOpen)}
        anchorPosition={anchorPosition ?? "upLeft"}
      >
        <ButtonProjectInfoBody projectId={projectId} />
      </EuiPopover>
    </Tooltip>
  );
};
