import { Project, Tenant } from "graphql/_Types";
import { uuid } from "utils/uuid";

const PROJECT_SHARE_ID = uuid();
const GET_ALL_PROJECTS_ID = uuid();
const PERMISSIONS_PROVIDER = uuid();
const ADMIN_PROJECTS = uuid();
const ADMIN_PROJECT = uuid();
export const queryKeys = {
  getAllProjects: (
    regions: "internal" | "external",
    tenantId?: Tenant["id"],
  ) => [GET_ALL_PROJECTS_ID, tenantId, regions],
  getAdminProjects: [ADMIN_PROJECTS],
  getAdminProject: (projectId: Project["id"]) => [ADMIN_PROJECT, projectId],
  modalProjectShare: (projectId: Project["id"]) => [
    PROJECT_SHARE_ID,
    projectId,
  ],
  permissionsProvider: (projectId: Project["id"]) => [
    PERMISSIONS_PROVIDER,
    projectId,
  ],
};
