import { ApplicationUser, Project, Tenant } from "graphql/_Types";
import { getRequestHeaders } from "utils/getRequestHeaders";
import Axios from "axios";
import { getEnvVar } from "ideas.env";
import { UserAccessLevel } from "types/UserAccessLevels";

export type ProjectDjango = {
  clone_source: Project["cloneSourceId"];
  date_archived: Project["dateArchived"];
  date_created: Project["dateCreated"];
  date_data_deleted: Project["dateDataDeleted"];
  date_deleted: Project["dateDeleted"];
  date_last_active: Project["dateLastActive"];
  default_user_access_level: UserAccessLevel["id"];
  default_user_access_level_display: string;
  description: Project["description"];
  icon: Project["icon"];
  icon_color: Project["iconColor"];
  icon_text: Project["iconText"];
  id: Project["id"];
  key: Project["key"];
  name: Project["name"];
  prefix: Project["prefix"];
  short_description: Project["shortDescription"];
  status: Project["status"];
  tenant_id: Project["tenantId"];
  active_storage_size: Project["activeStorageSize"];
  archived_storage_size: Project["archivedStorageSize"];
  user: {
    id: ApplicationUser["id"];
    username: ApplicationUser["username"];
  };
  tenant: {
    id: Tenant["id"];
    key: Tenant["key"];
    name: Tenant["name"];
  };
  pinned: boolean | null;
  used_credits: Project["usedCredits"];
  permissions: {
    role: UserAccessLevel["id"];
    role_display: string;
    download: boolean;
    edit: boolean;
    execute: boolean;
    grant_access: boolean;
    upload: boolean;
    view: boolean;
  };
};

export type AdminProjectDjango = Pick<
  ProjectDjango,
  | "id"
  | "date_last_active"
  | "default_user_access_level"
  | "default_user_access_level_display"
  | "key"
  | "name"
  | "active_storage_size"
  | "archived_storage_size"
  | "user"
  | "tenant"
  | "used_credits"
  | "permissions"
  | "date_created"
>;

/**
 * Queries a project
 */
export const getProjectDjango = async (projectId: Project["id"]) => {
  const headers = await getRequestHeaders();
  const { data } = await Axios.get<ProjectDjango>(
    getEnvVar("URL_LIBRARY_PROJECT")() + projectId,
    {
      headers,
    },
  );
  return data;
};

export const getProjectsDjango = async (externalUrl?: string) => {
  const headers = await getRequestHeaders();
  const response = await Axios.get<{
    results: ProjectDjango[];
  }>(
    externalUrl !== undefined
      ? getEnvVar("URL_LIBRARY_PROJECT")(externalUrl)
      : getEnvVar("URL_LIBRARY_PROJECT")(),
    {
      headers,
    },
  );
  return response.data.results;
};

/**
 * Queries a project through the ADMIN PROJECT view accessible to org admins only
 */
export const getAdminProjectDjango = async (projectId: Project["id"]) => {
  const headers = await getRequestHeaders();
  const { data } = await Axios.get<AdminProjectDjango>(
    getEnvVar("URL_LIBRARY_PROJECT_ADMIN_MANAGE") + projectId,
    {
      headers,
    },
  );
  return data;
};

export const getAdminProjectsDjango = async () => {
  const headers = await getRequestHeaders();
  const { data } = await Axios.get<AdminProjectDjango[]>(
    getEnvVar("URL_LIBRARY_PROJECT_ADMIN_MANAGE"),
    {
      headers,
    },
  );

  return data;
};
