/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CellValue } from "@inscopix/ideas-hyperformula";
import { memo } from "react";
import { CellValueRendererBase } from "../cell-value-renderers/CellValueRendererBase";
import { Tooltip } from "components/Tooltip/Tooltip";
import { getValidationErrors } from "./CellRendererBase.helpers";
import { DataTableColumnDefinition } from "../store/DataTableProvider.types";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0px 11px;
    background-color: inherit;
  `,
  error: css`
    background-color: #f8e9e9;
  `,
};

interface CellRendererBaseProps {
  columnDefinition: DataTableColumnDefinition;
  isColumnRequired: boolean;
  formula: string;
  value: CellValue;
}

/**
 * Cell renderer that renders any cell value
 */
export const CellRendererBase = ({
  columnDefinition,
  isColumnRequired,
  formula,
  value,
}: CellRendererBaseProps) => {
  const validationErrors = getValidationErrors({
    columnDefinition,
    isColumnRequired,
    value,
  });

  // Display nothing if cell does not have a formula
  if (formula === "") {
    return null;
  }

  // If there are multiple validation errors, render them in an unordered list
  const tooltipContent = (() => {
    if (validationErrors.length === 0) {
      return null;
    } else if (validationErrors.length === 1) {
      return validationErrors[0];
    } else {
      <ul>
        {validationErrors.map((error) => (
          <li key={error}>• {error}</li>
        ))}
      </ul>;
    }
  })();

  return (
    <Tooltip content={tooltipContent}>
      <span
        css={[
          styles.root,
          ...(validationErrors.length > 0 ? [styles.error] : []),
        ]}
      >
        <CellValueRendererBase value={value} />
      </span>
    </Tooltip>
  );
};

export const CellRendererBaseMemo = memo(CellRendererBase);
