import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useStore } from "zustand";
import AppLoading from "components/AppLoading/AppLoading";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import { DataTableStore, DataTableStoreState } from "./DataTableProvider.types";
import { createDataTableStore } from "./DataTableProvider.helpers";
import { Project } from "graphql/_Types";
import { useFileUploadContext } from "stores/upload/FileUploadProvider";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";

const DataTableContext = createContext<DataTableStore | undefined>(undefined);

interface DataTableProviderProps {
  children: ReactNode;
  projectKey: Project["key"];
}

/** Context provider for managing data for data tables and analysis tables */
export const DataTableProvider = ({
  children,
  projectKey,
}: DataTableProviderProps) => {
  const tenantId = useTenantContext((s) => s.currentTenant.id);
  const enqueueFile = useFileUploadContext((s) => s.enqueueFile);
  const cancelFileUploads = useFileUploadContext((s) => s.cancelFileUploads);
  const [store, setStore] = useState<DataTableStore>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    // Reset state
    setStore(undefined);
    setIsLoading(true);
    setError(undefined);

    // Create a new store
    createDataTableStore({
      tenantId,
      projectKey,
      enqueueFile,
      cancelFileUploads,
    })
      .then(setStore)
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [cancelFileUploads, enqueueFile, projectKey, tenantId]);

  if (error !== undefined) {
    throw error;
  }

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <DataTableContext.Provider value={store}>
      {children}
    </DataTableContext.Provider>
  );
};

/** Hook for consuming the {@link DataTableContext} */
export const useDataTableContext = <T,>(
  selector: (state: DataTableStoreState) => T,
  equalityFn?: (a: T, b: T) => boolean,
) => {
  const store = useContext(DataTableContext);
  assert(isDefined(store), new ContextOutOfBoundsError("DataTableContext"));
  return useStore(store, selector, equalityFn);
};
