/** @jsxImportSource @emotion/react */

import { EuiLink, EuiText } from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import {
  ColDef,
  ICellRendererParams,
  IDetailCellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";

import { AnalysisTableRowIdentifier } from "components/AnalysisTableRowIdentifier/AnalysisTableRowIdentifier";
import { ButtonViewTaskLogs } from "components/ButtonViewTaskLogs/ButtonViewTaskLogs";
import { TaskStatusBadge } from "components/TaskStatusBadge/TaskStatusBadge";
import {
  ToolParamValue,
  ToolSpec,
} from "components/ToolParamsGrid/ToolParamsGrid.types";
import { UserAvatarRenderer } from "components/UserAvatarRenderer/UserAvatarRenderer";
import {
  AnalysisTable,
  AnalysisTableGroup,
  AnalysisTableRow,
  ApplicationUser,
  File as DrsFile,
  Interval,
  Project,
  Task,
} from "graphql/_Types";
import { useRoutes } from "hooks/useRoutes";
import { mapValues } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { formatDate } from "utils/formatDate";
import { TaskStatus } from "types/constants";
import { roundToSignificant } from "utils/roundToSignificant";
import { Tooltip } from "components/Tooltip/Tooltip";
import {
  TasksTableDetailRenderer,
  TasksTableDetailRendererProps,
} from "./TasksTableDetailRenderer";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { isNonNullish } from "utils/isNonNullish";
import { ShortTaskId } from "components/ShortTaskId/ShortTaskId";

export const TASK_TABLE_DETAIL_RENDERER_HEIGHT = 400;

export type TasksTableTask = Pick<
  Task,
  "id" | "status" | "credits" | "created" | "cloned"
> & {
  duration?: {
    readableString: string;
    interval: Pick<Interval, "days" | "hours" | "minutes" | "seconds">;
  };
  toolName: string;
  table?: Pick<AnalysisTable, "name" | "identifiers" | "id"> & {
    toolSpec: ToolSpec;
    group: Pick<AnalysisTableGroup, "id" | "name">;
    row: Pick<AnalysisTableRow, "deleted"> & {
      data: Record<string, ToolParamValue>;
    };
    resultsByKey?: Record<string, DrsFile["id"]>;
  };
  isSystemUtil: boolean;
  user: Pick<ApplicationUser, "id" | "firstName" | "lastName"> | null;
};
export interface TasksTableProps {
  tasks: TasksTableTask[];
  project: Pick<Project, "key">;
  onClickFile: TasksTableDetailRendererProps["onClickFile"];
}

export const TasksTable = ({
  tasks,
  project,
  onClickFile,
}: TasksTableProps) => {
  const { routeMap } = useRoutes();
  const currentTenant = useTenantContext((s) => s.currentTenant);

  const totalCredits = tasks.reduce(
    // don't include cloned tasks in the total credits
    (sum, { credits, cloned }) => (sum += cloned ? 0 : credits ?? 0),
    0,
  );

  const rowData = useMemo(() => [...tasks], [tasks]);

  const columnDefs: ColDef<TasksTableTask>[] = useMemo(
    () => [
      {
        headerName: "Date",
        width: 170,
        sortable: true,
        sortingOrder: ["desc", "asc", null],
        valueGetter: ({ data }) => {
          return formatDate(data?.created);
        },
        cellRenderer: "agGroupCellRenderer",
      },
      {
        headerName: "User",
        width: 70,
        resizable: false,
        cellRenderer: ({ data }: ICellRendererParams<TasksTableTask>) => {
          if (data === undefined) {
            captureException("Data should be defined in grid");
            return null;
          }
          if (data.user === null) {
            return null;
          }

          return <UserAvatarRenderer userId={data.user.id} />;
        },
        sortable: true,
        valueGetter: ({ data }) =>
          data?.user ? `${data.user.firstName} ${data.user.lastName}` : "",
      },
      {
        headerName: "Source",
        flex: 2,
        minWidth: 150,
        sortable: true,
        valueGetter: ({ data }) => data?.table?.name ?? "",
        cellRenderer: ({ data }: ICellRendererParams<TasksTableTask>) => {
          if (data === undefined) {
            captureException("Data should be defined in grid");
            return null;
          }

          if (data.table === undefined) {
            return (
              <EuiText color={data.isSystemUtil ? "text" : "subdued"} size="xs">
                {data.toolName}
              </EuiText>
            );
          }
          return (
            <Tooltip
              content={
                data.table?.row.deleted
                  ? "This task has been deleted from the analysis table"
                  : ""
              }
            >
              <EuiLink
                color={data.table?.row.deleted ? "subdued" : undefined}
                to={
                  routeMap.PROJECT_ANALYSIS_TABLE_GROUP.dynamicPath({
                    tenantKey: currentTenant.key,
                    projectKey: project.key,
                    analysisTableGroupId: data.table.group.id,
                  }).path
                }
              >
                {data.table.name}
              </EuiLink>
            </Tooltip>
          );
        },
      },
      {
        headerName: "Status",
        width: 120,
        resizable: false,
        sortable: true,
        valueGetter: ({ data }) =>
          data?.status !== undefined ? TaskStatus[data?.status] : "",
        cellRenderer: ({ data }: ICellRendererParams<TasksTableTask>) => {
          if (data === undefined) {
            captureException("Data should be defined in grid");
            return null;
          }
          return <TaskStatusBadge taskStatus={data.status} />;
        },
      },
      {
        headerName: "Log",
        width: 70,
        resizable: false,
        cellRenderer: ({ data }: ICellRendererParams<TasksTableTask>) => {
          if (data === undefined) {
            captureException("Data should be defined in grid");
            return null;
          }
          return (
            <ButtonViewTaskLogs
              taskId={data.id}
              taskStatus={data.status}
              taskCreated={data.created}
            />
          );
        },
      },
      {
        headerName: "ID",
        flex: 1,
        maxWidth: 300,
        minWidth: 100,
        valueGetter: ({ data }) => data?.id ?? "",
        cellRenderer: ({ data }: ICellRendererParams<TasksTableTask>) => {
          if (data === undefined) {
            captureException("Data should be defined in grid");
            return null;
          }
          if (data.table === undefined) {
            return <ShortTaskId taskId={data.id} />;
          }

          return (
            <AnalysisTableRowIdentifier
              params={data.table.row.data}
              table={data.table}
              task={data}
              toolSpec={data.table.toolSpec}
            />
          );
        },
      },
      {
        headerName: "Duration",
        flex: 0.5,
        minWidth: 95,
        maxWidth: 150,
        cellRenderer: ({ data }: ICellRendererParams<TasksTableTask>) => {
          if (data === undefined) {
            captureException("Data should be defined in grid");
            return null;
          }
          return <span>{data.duration?.readableString}</span>;
        },
        sortable: true,
        valueGetter: ({ data }) =>
          moment
            .duration(mapValues(data?.duration?.interval, (val) => val ?? 0))
            .asSeconds(),
      },
      {
        headerName: "Compute Credits",
        width: 180,
        pinned: "right",
        sortable: true,
        valueGetter: ({ data }) => {
          if (data === undefined) {
            captureException("Data should be defined in grid");
          }

          return data?.credits ?? 0;
        },
        valueFormatter: ({
          data,
          value,
        }: ValueFormatterParams<TasksTableTask, number>) => {
          if (isNonNullish(value)) {
            const credits = roundToSignificant(value).toString();
            const cloned = data?.cloned;
            return cloned ? `[${credits}]` : credits;
          }
          return "";
        },
        cellStyle: ({ data }) => {
          if (data?.cloned) {
            // format cloned task compute credit
            return { color: "grey", fontStyle: "italic" };
          }
          return null;
        },
        tooltipValueGetter: ({ data }) =>
          data?.cloned ? "Copied tasks do not count towards usage" : "",
      },
    ],
    [currentTenant.key, project.key, routeMap.PROJECT_ANALYSIS_TABLE_GROUP],
  );

  const defaultColDef = useMemo(
    () => ({ resizable: true, suppressHeaderMenuButton: true }),
    [],
  );

  return (
    <AgGridReact<TasksTableTask>
      className="ag-theme-balham-cell-borders"
      statusBar={{
        statusPanels: [
          {
            key: "credits",
            align: "right",
            statusPanel: () => {
              return (
                <div css={{ padding: "5px 0px" }}>
                  <strong>
                    {roundToSignificant(totalCredits)} compute credits
                  </strong>
                </div>
              );
            },
          },
        ],
      }}
      rowData={rowData}
      rowHeight={35}
      defaultColDef={defaultColDef}
      enableRangeSelection
      columnDefs={columnDefs}
      masterDetail={true}
      isRowMaster={(task) => task.table !== undefined}
      detailCellRenderer={(
        params: IDetailCellRendererParams<TasksTableTask>,
      ) => {
        const table = params.data?.table;
        if (params.data === undefined || table === undefined) {
          return null;
        }
        return (
          <TasksTableDetailRenderer
            {...params}
            data={{ ...params.data, table }}
            onClickFile={onClickFile}
          />
        );
      }}
      detailRowHeight={TASK_TABLE_DETAIL_RENDERER_HEIGHT}
    />
  );
};
