import { captureException } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { getProjectsDjango } from "django/libraryProjectDjango";
import { queryKeys } from "django/queryKeys";
import { Tenant } from "graphql/_Types";
import { useRegionsContext } from "providers/RegionsProvider";
import { useCallback, useMemo } from "react";

type Options = { tenantId?: Tenant["id"] };
const getProjects = async (externalUrl?: string) => {
  const data = await getProjectsDjango(externalUrl);

  return data.map((project) => ({
    cloneSource: project.clone_source,
    dateArchived: project.date_archived,
    dateCreated: project.date_created,
    usedCredits: project.used_credits,
    dateLastActive: project.date_last_active,
    defaultUserAccessLevel: project.default_user_access_level,
    defaultUserAccessLevelDisplay: project.default_user_access_level_display,
    description: project.description,
    icon: project.icon,
    iconColor: project.icon_color,
    iconText: project.icon_text,
    id: project.id,
    key: project.key,
    name: project.name,
    prefix: project.prefix,
    shortDescription: project.short_description,
    status: project.status,
    tenantId: project.tenant.id,
    userId: project.user.id,
    activeStorageSize: project.active_storage_size ?? "0",
    archivedStorageSize: project.archived_storage_size ?? "0",
    user: project?.user?.username,
    tenantKey: project?.tenant?.key,
    tenantName: project?.tenant?.name,
    pinned: project.pinned ?? false,
    role_display: project.permissions.role_display,
    userPermissions: {
      view: project.permissions.view,
      edit: project.permissions.edit,
      download: project.permissions.download,
      grant_access: project.permissions.grant_access,
      execute: project.permissions.execute,
      upload: project.permissions.upload,
    },
  }));
};

export const useGetProjects = (options?: Options) => {
  const { externalRegions } = useRegionsContext();

  const getExternalProjects = useCallback(async () => {
    if (options?.tenantId !== undefined) {
      return [];
    }
    const results = await Promise.all(
      externalRegions.map(async (region) => ({
        // region,
        projects: await getProjects(region.urlDjango).then((project) => ({
          ...project,
          region,
        })),
      })),
    );
    return results.flatMap((region) => region.projects);
  }, [externalRegions, options?.tenantId]);

  const getInternalProjects = useCallback(() => getProjects(), []);

  const {
    data: internalProjects,
    isError: internalProjectsError,
    isLoading: internalProjectsLoading,
  } = useQuery({
    queryFn: getInternalProjects,
    queryKey: queryKeys.getAllProjects("internal", options?.tenantId),
    refetchOnMount: "always",
  });

  /**
   *
   *
   * REMOVE THIS NOTE BEFORE MERGING
   *
   * You can't test this locally with dev unless you fake permissions because dev doesn't have the serializer changes
   * You should still be able to see the external query being executed and see the results in the network traffic, but the
   * response won't include the expected permissions so will cause a crash trying to parse the results.
   *
   *
   */
  const {
    data: externalProjects,
    isError: externalProjectsError,
    isLoading: externalProjectsLoading,
  } = useQuery({
    queryFn: getExternalProjects,
    queryKey: queryKeys.getAllProjects("external", options?.tenantId),
    refetchOnMount: "always",
  });

  // need to sort despite queries already being sorted because we are merging
  const projects = useMemo(() => {
    if (internalProjects === undefined || externalProjects === undefined) {
      return undefined;
    }

    return (
      [...internalProjects, ...externalProjects]
        // tenant key is required for routing to the project
        .filter(
          (project): project is typeof project & { tenantKey: string } => {
            if (project.tenantKey === undefined) {
              captureException("Project missing access to tenant key", {
                extra: {
                  project,
                },
              });
            }
            return project.tenantKey !== undefined;
          },
        )
        .sort((a, b) => {
          if (a.dateCreated < b.dateCreated) {
            return 1;
          }
          if (a.dateCreated > b.dateCreated) {
            return -1;
          }
          return 0;
        })
    );
  }, [externalProjects, internalProjects]);

  if (internalProjectsLoading || externalProjectsLoading) {
    return { loading: true, projects: undefined, error: undefined };
  }

  if (
    (!internalProjectsLoading && !externalProjectsLoading) ||
    internalProjectsError ||
    externalProjectsError
  ) {
    if (projects === undefined) {
      return {
        error: new Error("Failed to retrieve projects"),
        projects: undefined,
        loading: false,
      };
    }
  }

  return { projects, loading: false, error: undefined };
};
