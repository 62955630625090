import { useState } from "react";

import {
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuItemIcon,
  EuiContextMenuPanel,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { Project } from "graphql/_Types";
import ModalCloneProject from "components/ModalProject/ModalCloneProject";
import ModalDeleteProject from "components/ModalDeleteProject/ModalDeleteProject";
import ModalEditProject from "components/ModalProject/ModalEditProject";
import { ProjectPermissions } from "pages/project/ProjectPermissionProvider";
import { projectScheduleDeleteDjango } from "hooks/useProjectSheduleDeleteDjango/useProjectSheduleDeleteDjango";

type ModalOptions = "edit" | "copy" | "archive" | "delete";

interface ProjectsCardActionsProps {
  projectId: Project["id"];
  // Denotes if actions are available that modify/destroy this project
  permissions: ProjectPermissions;
  // Completely disables the menu
  disabled?: boolean;
}

export const ProjectsCardActions = ({
  projectId,
  permissions,
  disabled,
}: ProjectsCardActionsProps) => {
  const [activeModal, setActiveModal] = useState<ModalOptions>();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen((prev) => !prev);
  };

  const actions: {
    key: ModalOptions;
    icon: EuiContextMenuItemIcon;
    disabled: boolean;
  }[] = [
    {
      key: "edit",
      icon: "pencil",
      disabled: !permissions.edit,
    },
    {
      key: "copy",
      icon: "copy",
      disabled: !permissions.download,
    },
    {
      key: "delete",
      icon: "trash",
      disabled: !permissions.grant_access,
      // TODO ID-1647 https://inscopix.atlassian.net/browse/ID-1647
    },
  ];

  return (
    <>
      <EuiPopover
        button={
          <EuiButtonIcon
            isDisabled={disabled}
            aria-label="Actions"
            iconType="gear"
            size="s"
            color="text"
            onClick={togglePopover}
          />
        }
        isOpen={popoverOpen}
        closePopover={() => setPopoverOpen(false)}
        panelPaddingSize="none"
        anchorPosition="leftCenter"
      >
        <EuiContextMenuPanel
          items={actions.map(({ key, icon, disabled }) => (
            <EuiContextMenuItem
              key={key}
              icon={icon}
              onClick={(e) => {
                e.stopPropagation();
                setPopoverOpen(false);
                setActiveModal(key);
              }}
              disabled={disabled}
            >
              {key.charAt(0).toUpperCase() + key.substring(1)}
            </EuiContextMenuItem>
          ))}
        />
      </EuiPopover>
      {activeModal === "edit" && (
        <ModalEditProject
          onClose={() => setActiveModal(undefined)}
          projectId={projectId}
        />
      )}
      {activeModal === "copy" && (
        <ModalCloneProject
          onClose={() => setActiveModal(undefined)}
          projectId={projectId}
        />
      )}
      {
        activeModal === "archive" && null
        // TODO ID-1645 https://inscopix.atlassian.net/browse/ID-1645
        // <ModalArchiveProject
        //   projectId={projectId}
        //   onClose={() => setActiveModal(undefined)}
        // />
      }
      {activeModal === "delete" && (
        // TODO ID-1647 https://inscopix.atlassian.net/browse/ID-1647
        <ModalDeleteProject
          deleteProject={projectScheduleDeleteDjango}
          projectId={projectId}
          onClose={() => setActiveModal(undefined)}
        />
      )}
    </>
  );
};
