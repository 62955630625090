import {
  AgGridReact,
  CustomCellEditorProps,
  CustomCellRendererProps,
} from "ag-grid-react";
import { CellValue } from "@inscopix/ideas-hyperformula";
import {
  CellEditRequestEvent,
  ColumnResizedEvent,
  SelectionChangedEvent,
  ColGroupDef,
  ColDef,
  ColumnMovedEvent,
  GetRowIdParams,
  DragStartedEvent,
} from "ag-grid-community";
import { useDataTableContext } from "../store/DataTableProvider";
import { ColumnHeaderBase } from "../column-headers/ColumnHeaderBase";
import { CellRendererRowIndex } from "../cell-renderers/CellRendererRowIndex";
import { CellRendererBaseMemo } from "../cell-renderers/CellRendererBase";
import { memo, useCallback, useMemo } from "react";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { isNonNull } from "utils/isNonNull";
import { captureException } from "@sentry/react";
import { CellEditorBaseMemo } from "../cell-editors/CellEditorBase";

export type DataTableRowData = {
  id: string;
  editable: boolean;
  cells: {
    formula: string;
    value: CellValue;
  }[];
};

const colDefRowIndex: ColDef<DataTableRowData> = {
  cellRenderer: CellRendererRowIndex,
  cellStyle: {
    display: "flex",
    alignItems: "center",
  },
  colId: "rowNumber",
  headerName: "",
  pinned: "left",
  resizable: false,
  width: 78,
  lockPosition: true,
  suppressHeaderMenuButton: true,
  checkboxSelection: true,
  headerCheckboxSelection: true,
};

/**
 * Component that renders columns, rows and cells for data tables and analysis
 * tables
 */
const DataTableInner = () => {
  const selectedTable = useDataTableContext((s) => {
    const table = s.tables.find((table) => table.id === s.selectedTableId);
    assert(isDefined(table));
    return table;
  });
  const setSelectedRowIds = useDataTableContext((s) => s.setSelectedRowIds);
  const setCellFormula = useDataTableContext((s) => s.setCellFormula);
  const resizeColumn = useDataTableContext((s) => s.resizeColumn);
  const moveColumn = useDataTableContext((s) => s.moveColumn);

  const customColDefs: (
    | ColDef<DataTableRowData>
    | ColGroupDef<DataTableRowData>
  )[] = useMemo(() => {
    // Initialize column group definitions
    const groupDefs: ColGroupDef<DataTableRowData>[] =
      selectedTable.columnGroups.map((columnGroup) => ({
        groupId: columnGroup.id,
        headerName: columnGroup.name,
        children: [],
      }));

    // Accumulator holding all column and column group definitions
    const allDefinitions: (
      | ColDef<DataTableRowData>
      | ColGroupDef<DataTableRowData>
    )[] = [];

    // Create column definitions
    selectedTable.columns.forEach((column, index) => {
      const colDef: ColDef<DataTableRowData> = {
        cellEditor: (props: CustomCellEditorProps<DataTableRowData>) => (
          <CellEditorBaseMemo
            api={props.api}
            onValueChange={props.onValueChange}
            stopEditing={props.stopEditing}
            tableId={selectedTable.id}
            tableKind={selectedTable.kind}
            columnId={column.id}
            columnName={column.name}
            columnDefinition={column.definition}
            rowId={props.data.id}
            formula={props.data.cells[index].formula}
            value={props.data.cells[index].value}
          />
        ),
        cellRenderer: (props: CustomCellRendererProps<DataTableRowData>) => {
          const rowData = props.data;
          assert(isDefined(rowData));
          return (
            <CellRendererBaseMemo
              columnDefinition={column.definition}
              isColumnRequired={column.required}
              formula={rowData.cells[index].formula}
              value={rowData.cells[index].value}
            />
          );
        },
        cellStyle: { padding: 0 },
        colId: column.id,
        headerComponent: ColumnHeaderBase,
        headerComponentParams: {
          index,
          isColumnDeletable: column.deletable,
          tableId: selectedTable.id,
          tableKind: selectedTable.kind,
          isPinned: column.pinned,
        },
        headerName: column.name,
        editable: ({ data }) => column.editable && (data?.editable ?? false),
        resizable: true,
        pinned: column.pinned,
        lockPinned: true,
        width: column.width,
      };

      // If the column belongs to a group, add it to the group definition.
      if (column.group !== null) {
        const group = groupDefs.find((group) => group.groupId === column.group);
        assert(isDefined(group));
        group.children.push(colDef);
        if (group.children.length === 1) {
          allDefinitions.push(group);
        }
      } else {
        allDefinitions.push(colDef);
      }
    });

    return allDefinitions;
  }, [
    selectedTable.columnGroups,
    selectedTable.columns,
    selectedTable.id,
    selectedTable.kind,
  ]);

  const columnDefs = useMemo(() => {
    return [colDefRowIndex, ...customColDefs];
  }, [customColDefs]);

  const rowData = useMemo(() => {
    return selectedTable.rows.map((row) => ({
      id: row.id,
      editable: row.editable,
      cells: row.cells,
    }));
  }, [selectedTable.rows]);

  const handleCellEditRequest = useCallback(
    (e: CellEditRequestEvent<DataTableRowData>) => {
      const tableId = selectedTable.id;
      const columnId = e.column.getColId();
      const colIndex = selectedTable.columns.findIndex(
        ({ id }) => id === columnId,
      );
      const rowId = e.data.id;
      const oldFormula = e.data.cells[colIndex].formula;

      // We should never receive anything but a string or null. If we do, there
      // is an AG Grid event that has not been handled.
      if (typeof e.newValue !== "string" && e.newValue !== null) {
        captureException("Cell edit request received invalid formula");
        return;
      }

      const newFormula = (e.newValue as string | null) ?? "";
      if (oldFormula !== newFormula) {
        void setCellFormula({ tableId, columnId, rowId }, newFormula);
      }
    },
    [selectedTable.columns, selectedTable.id, setCellFormula],
  );

  const handleSelectionChanged = useCallback(
    (e: SelectionChangedEvent<DataTableRowData>) => {
      const selectedRowIds = e.api.getSelectedRows().map((row) => row.id);
      setSelectedRowIds(selectedRowIds);
    },
    [setSelectedRowIds],
  );

  const handleColumnResized = useCallback(
    (e: ColumnResizedEvent<DataTableRowData>) => {
      const isColumnStillDragging = !e.finished;
      if (isColumnStillDragging || e.source !== "uiColumnResized") {
        return;
      }

      if (isNonNull(e.column)) {
        void resizeColumn({
          tableId: selectedTable.id,
          columnId: e.column.getColId(),
          newWidth: e.column.getActualWidth(),
        });
      }
    },
    [resizeColumn, selectedTable.id],
  );

  const handleColumnMoved = useCallback(
    (e: ColumnMovedEvent<DataTableRowData>) => {
      const columnId = e.column?.getColId();

      if (e.finished && isDefined(columnId)) {
        const oldPosition = selectedTable.columns.findIndex(
          ({ id }) => id === columnId,
        );

        const newPosition = e.api
          .getColumnState()
          .map(({ colId }) => colId)
          // Remove any untracked columns (e.g. row index column)
          .filter((id) =>
            selectedTable.columns.some((column) => column.id === id),
          )
          .findIndex((id) => id === columnId);

        if (oldPosition !== newPosition) {
          void moveColumn({
            tableId: selectedTable.id,
            columnId: columnId,
            newPosition: newPosition,
          });
        }
      }
    },
    [moveColumn, selectedTable.columns, selectedTable.id],
  );

  const handleDragStarted = useCallback((e: DragStartedEvent) => {
    // Prevent cell editors from staying open when reordering columns
    e.api.stopEditing();
  }, []);

  const getRowId = useCallback(
    ({ data }: GetRowIdParams<DataTableRowData>) => data.id,
    [],
  );

  return (
    <AgGridReact<DataTableRowData>
      key={selectedTable.id}
      className="ag-theme-balham-cell-borders"
      columnDefs={columnDefs}
      getRowId={getRowId}
      rowData={rowData}
      rowHeight={35}
      readOnlyEdit
      onCellEditRequest={handleCellEditRequest}
      rowSelection="multiple"
      suppressRowClickSelection
      onDragStarted={handleDragStarted}
      suppressRowHoverHighlight
      suppressDragLeaveHidesColumns
      onSelectionChanged={handleSelectionChanged}
      onColumnResized={handleColumnResized}
      onColumnMoved={handleColumnMoved}
      // Prevent the grid from automatically unpinning columns
      // https://www.ag-grid.com/react-data-grid/grid-options/#reference-columnPinning-processUnpinnedColumns
      processUnpinnedColumns={() => []}
      reactiveCustomComponents
      onRowDataUpdated={(e) => e.api.stopEditing()}
    />
  );
};

export const DataTable = memo(function DataTable() {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  return isDefined(selectedTableId) ? (
    <DataTableInner />
  ) : (
    <AgGridReact
      key="empty"
      className="ag-theme-balham-cell-borders"
      columnDefs={[]}
      rowData={[]}
    />
  );
});
