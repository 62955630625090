import { createStore } from "zustand";
import { CellAddress, DataTableStoreState } from "./DataTableProvider.types";
import { immer } from "zustand/middleware/immer";
import { Project, Tenant } from "graphql/_Types";
import * as DjangoClient from "./DataTableProvider.api";
import { first, uniqWith } from "lodash";
import { initSpreadsheetEngine } from "./engine";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { createProjectActionQueue } from "./queue";
import {
  AstNodeType,
  Group,
  HyperFormula,
  IdeasFile,
  RowIdentifier,
} from "@inscopix/ideas-hyperformula";
import { recalculateCellValues, SideEffects, traverseAst } from "./SideEffects";
import { QueuedFile } from "stores/upload/FileUploadProvider.helpers";
import { ProcessingStatus } from "types/constants";
import { FILE_TYPES_BY_KEY } from "types/FileTypes";
import { identifyFile } from "utils/identifyFile";

type CreateDataTableOptions = {
  tenantId: Tenant["id"];
  projectKey: Project["key"];
  enqueueFile: (file: QueuedFile) => void;
  cancelFileUploads: (fileIds: string[]) => void;
};

/**
 * Create a new Zustand store for storing data table and analysis table data
 * and actions for updating the stored data.
 * @param projectKey
 * @returns The Zustand store.
 */
export const createDataTableStore = async ({
  tenantId,
  projectKey,
  enqueueFile,
  cancelFileUploads,
}: CreateDataTableOptions) => {
  // Fetch cell formulas from server
  const project = await DjangoClient.getProject({ projectKey });
  const data = await DjangoClient.getTableData({ projectId: project.id });

  // Initialize data and define actions
  return createStore<DataTableStoreState>()(
    immer((set, get) => {
      // Initialize a new empty HyperFormula engine instance
      let engine = HyperFormula.buildEmpty();

      // Wait until the store is initialized to register the sheets with the engine.
      // This is necessary because the formula context, uses `get` which will not
      // return data until after the store has been created.
      setTimeout(function registerSheets() {
        const isStoreInitialized = get() !== undefined;

        if (!isStoreInitialized) {
          setTimeout(registerSheets, 1);
          return;
        }

        engine = initSpreadsheetEngine(data, set, get);
      }, 1);

      /**
       * Converts an IDEAS cell address into a HyperFormula cell address.
       * @param address An IDEAS cell address.
       * @returns The address as a HyperFormula cell address.
       */
      const convertIdeasCellAddress = (address: CellAddress) => {
        const { tableId, columnId, rowId } = address;
        const table = get().tables.find(({ id }) => id === tableId);
        assert(isDefined(table), "Failed to read table ID from cell address");
        const sheetId = engine.getSheetId(table.key);
        assert(isDefined(sheetId), "Failed to read sheet ID from cell address");
        const colIdx = table.columns.findIndex(({ id }) => id === columnId);
        const rowIdx = table.rows.findIndex(({ id }) => id === rowId);
        return { sheet: sheetId, col: colIdx, row: rowIdx };
      };

      // Format store data using the cell values computed by the engine
      const tables = data.map((table) => ({
        ...table,
        columnGroups: table.column_groups,
        columns: table.columns,
        rows: table.rows.map((row) => ({
          id: row.id,
          index: row.index,
          editable: row.editable,
          cells: row.cells.map((cell) => ({
            isCustomFormula: cell.is_custom_formula,
            formula: cell.formula ?? "",
            value: null,
          })),
        })),
      }));

      // Initialize a new action queue
      const { enqueueAction } = createProjectActionQueue(set, get);

      return {
        tables,

        selectedTableId: first(tables)?.id,

        selectedFileId: undefined,

        selectedRowIds: [],

        isAdvancedMode: false,

        syncStatus: {
          status: "idle",
        },

        fileMap: {},

        metadatumMap: {},

        /**
         * Gets a file from the cache. If the file has not been previously
         * fetched, retrieve it from the server and notify the caller through a
         * callback.
         * @param options
         * @returns The cached file.
         */
        getFile: ({ fileId, onChange }) => {
          const file = get().fileMap[fileId];

          if (file === undefined) {
            DjangoClient.getProjectFile({ fileId })
              .then((file) => {
                set((state) => {
                  state.fileMap[fileId] = file;
                });
                onChange(file);
              })
              .catch((error) => {
                set((state) => {
                  state.fileMap[fileId] = error as Error;
                });
                onChange(error as Error);
              });
          }

          return file;
        },

        /**
         * Gets a file metadatum value from the cache. If the value has not
         * been previously fetched, retrieve it from the server and notify
         * the caller through a callback.
         * @param options
         * @returns The cached metadatum value.
         */
        getFileMetadatum: ({ fileId, metadatumKey, onChange }) => {
          const metadatumValue = get().metadatumMap[fileId]?.[metadatumKey];

          if (metadatumValue === undefined) {
            DjangoClient.getFileMetadatumValue({ fileId, metadatumKey })
              .then((metadatumValue) => {
                set((state) => {
                  const metadatumMap =
                    state.metadatumMap as DataTableStoreState["metadatumMap"];
                  metadatumMap[fileId] = {
                    ...metadatumMap[fileId],
                    ...{ [metadatumKey]: metadatumValue },
                  };
                });
                onChange(metadatumValue);
              })
              .catch((error) => {
                set((state) => {
                  const metadatumMap =
                    state.metadatumMap as DataTableStoreState["metadatumMap"];
                  metadatumMap[fileId] = {
                    ...metadatumMap[fileId],
                    ...{ [metadatumKey]: error as Error },
                  };
                });
                onChange(error as Error);
              });
          }

          return metadatumValue;
        },

        /**
         * Sets the selected table.
         * @param tableId
         */
        setSelectedTableId: (tableId) => {
          set((state) => {
            state.selectedTableId = tableId;
            state.selectedRowIds = [];
          });
        },

        /**
         * Sets the selected file.
         * @param fileId
         */
        setSelectedFileId: (fileId) => {
          set((state) => {
            state.selectedFileId = fileId;
          });
        },

        /**
         * Sets the selected rows.
         * @param rowIds
         */
        setSelectedRowIds: (rowIds) => {
          set((state) => {
            state.selectedRowIds = rowIds;
          });
        },

        /**
         * Toggles advanced mode.
         * @param isAdvancedMode
         */
        setIsAdvancedMode: (isAdvancedMode) => {
          set((state) => {
            state.isAdvancedMode = isAdvancedMode;
          });
        },

        /**
         * Sets the formula for a cell at a specified address.
         * @param address
         * @param newFormula
         * Creates a new data table.
         * @param options.name
         * @param options.key
         * @returns The table ID.
         */
        createDataTable: ({ name, key }) => {
          const sideEffects = new SideEffects(engine, get, set);

          return enqueueAction({
            // We can't update state optimistically for this action
            onEnqueue: () => undefined,

            onDequeue: async () => {
              // Persist changes to the server
              const table = await DjangoClient.createDataTable({
                name,
                key,
                projectId: project.id,
                sideEffects: sideEffects.serialize(),
              });

              const formattedTable = {
                id: table.id,
                kind: "data" as const,
                key,
                name,
                columnGroups: table.column_groups,
                columns: table.columns,
                rows: table.rows.map((row) => ({
                  id: row.id,
                  index: row.index,
                  editable: row.editable,
                  cells: table.columns.map((column) => ({
                    isCustomFormula: false,
                    formula: column.default_formula ?? "",
                    value: null,
                  })),
                })),
              };

              // Update store state
              set((state) => {
                state.tables.push(formattedTable);
                state.selectedTableId = table.id;
              });

              // Register new table, columns and rows with engine
              engine.addSheet(key);
              const sheetId = engine.getSheetId(key);
              assert(isDefined(sheetId));
              engine.addRows(sheetId, [0, table.rows.length]);
              engine.addColumns(sheetId, [0, table.columns.length]);
              engine.setSheetContent(
                sheetId,
                formattedTable.rows.map((row) =>
                  row.cells.map((cell) => cell.formula),
                ),
              );

              // Update cell values
              set((state) => {
                const table = state.tables.find(
                  ({ id }) => id === formattedTable.id,
                );
                assert(isDefined(table));
                table.rows.forEach((row, rowIndex) => {
                  row.cells.forEach((cell, colIndex) => {
                    cell.value = engine.getCellValue({
                      sheet: sheetId,
                      col: colIndex,
                      row: rowIndex,
                    });
                  });
                });
              });

              return table.id;
            },
          });
        },

        /**
         * Updates a data table.
         * @param options.tableId
         * @param options.name
         * @param options.key
         * @returns The table ID.
         */
        updateDataTable: ({ tableId, name, key }) => {
          const sideEffects = new SideEffects(engine, get, set);

          return enqueueAction({
            onEnqueue: () => {
              // Parse table ID
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));

                if (isDefined(name)) {
                  table.name = name;
                }

                if (isDefined(key)) {
                  table.key = key;
                }
              });

              // Register new table key with engine and recalculate stale row
              // identifiers
              if (isDefined(key)) {
                engine.renameSheet(sheetId, key);
                recalculateCellValues(engine, (_address, _formula, value) => {
                  return (
                    value instanceof RowIdentifier &&
                    value.tableKey === table.key
                  );
                });
              }

              // Apply side-effects
              sideEffects.init();

              if (isDefined(key)) {
                const updatedCells = sideEffects.updateRowIdentifiers(
                  table.key,
                  key,
                );
                updatedCells.forEach((cell) => {
                  engine.setCellContents(cell.address, cell.newFormula);
                });
              }

              sideEffects.applyOnStoreState();
            },

            onDequeue: async () => {
              // Persist changes to the server
              await DjangoClient.updateDataTable({
                tableId,
                name,
                key,
                sideEffects: sideEffects.serialize(),
              });
              return tableId;
            },
          });
        },

        /**
         * Updates a analysis table.
         * @param options.tableId
         * @param options.name
         * @param options.key
         * @returns The table ID.
         */
        updateAnalysisTable: ({ tableId, name, key }) => {
          const sideEffects = new SideEffects(engine, get, set);

          return enqueueAction({
            onEnqueue: () => {
              // Parse table ID
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));

                if (isDefined(name)) {
                  table.name = name;
                }

                if (isDefined(key)) {
                  table.key = key;
                }
              });

              // Register new table key with engine and recalculate stale row
              // identifiers
              if (isDefined(key)) {
                engine.renameSheet(sheetId, key);
                recalculateCellValues(engine, (_address, _formula, value) => {
                  return (
                    value instanceof RowIdentifier &&
                    value.tableKey === table.key
                  );
                });
              }

              // Apply side-effects
              sideEffects.init();

              if (isDefined(key)) {
                const updatedCells = sideEffects.updateRowIdentifiers(
                  table.key,
                  key,
                );
                updatedCells.forEach((cell) => {
                  engine.setCellContents(cell.address, cell.newFormula);
                });
              }

              sideEffects.applyOnStoreState();
            },

            onDequeue: async () => {
              // Persist changes to the server
              await DjangoClient.updateAnalysisTable({
                tableId,
                name,
                key,
                sideEffects: sideEffects.serialize(),
              });
              return tableId;
            },
          });
        },

        /**
         * Creates a new analysis table.
         * @param options.toolVersionId
         * @param options.name
         * @param options.key
         * @returns The table ID.
         */
        createAnalysisTable: ({ toolVersionId, name, key }) => {
          const sideEffects = new SideEffects(engine, get, set);

          return enqueueAction({
            // We can't update state optimistically for this action
            onEnqueue: () => undefined,

            onDequeue: async () => {
              // Persist changes to the server
              const table = await DjangoClient.createAnalysisTable({
                toolVersionId,
                name,
                key,
                projectId: project.id,
                sideEffects: sideEffects.serialize(),
              });

              const formattedTable = {
                id: table.id,
                kind: "analysis" as const,
                key,
                name,
                columnGroups: table.column_groups,
                columns: table.columns,
                rows: table.rows.map((row) => ({
                  id: row.id,
                  index: row.index,
                  editable: row.editable,
                  cells: table.columns.map((column) => ({
                    isCustomFormula: false,
                    formula: column.default_formula ?? "",
                    value: null,
                  })),
                })),
              };

              // Update store state
              set((state) => {
                state.tables.push(formattedTable);
                state.selectedTableId = table.id;
              });

              // Register new table, columns and rows with engine
              engine.addSheet(key);
              const sheetId = engine.getSheetId(key);
              assert(isDefined(sheetId));
              engine.addRows(sheetId, [0, table.rows.length]);
              engine.addColumns(sheetId, [0, table.columns.length]);
              engine.setSheetContent(
                sheetId,
                formattedTable.rows.map((row) =>
                  row.cells.map((cell) => cell.formula),
                ),
              );

              // Update cell values
              set((state) => {
                const table = state.tables.find(
                  ({ id }) => id === formattedTable.id,
                );
                assert(isDefined(table));
                table.rows.forEach((row, rowIndex) => {
                  row.cells.forEach((cell, colIndex) => {
                    cell.value = engine.getCellValue({
                      sheet: sheetId,
                      col: colIndex,
                      row: rowIndex,
                    });
                  });
                });
              });

              return table.id;
            },
          });
        },

        /**
         * Creates a new data table column.
         * @param options
         * @returns The column ID.
         */
        createColumn: ({
          tableId,
          name,
          editable,
          defaultFormula,
          definition,
        }) => {
          const sideEffects = new SideEffects(engine, get, set);

          return enqueueAction({
            // We can't update state optimistically for this action
            onEnqueue: () => undefined,

            onDequeue: async () => {
              // Parse table ID
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));

              // Persist changes to the server
              const column = await DjangoClient.createDataTableColumn({
                tableId,
                name,
                editable,
                defaultFormula,
                definition,
                sideEffects: sideEffects.serialize(),
              });

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));
                table.columns.push(column);
                table.rows.forEach((row) => {
                  row.cells.push({
                    isCustomFormula: false,
                    formula: defaultFormula ?? "",
                    value: null,
                  });
                });
              });

              // Register new column with engine
              const colIndex = table.columns.length;
              engine.addColumns(sheetId, [colIndex, 1]);
              engine.setCellContents(
                { sheet: sheetId, col: colIndex, row: 0 },
                new Array(table.rows.length).fill([defaultFormula]),
              );

              return column.id;
            },
          });
        },

        /**
         * Adds a specified number of rows to a table.
         * @param options.tableId
         * @param options.numRows
         * @returns An array of row IDs.
         */
        createRows: ({ tableId, numRows }) => {
          const sideEffects = new SideEffects(engine, get, set);

          return enqueueAction({
            // We can't update state optimistically for this action
            onEnqueue: () => undefined,

            onDequeue: async () => {
              // Parse table ID
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));
              const defaultFormulas = table.columns.map(
                (column) => column.default_formula,
              );

              // Persist changes to the server
              const rows = await DjangoClient.createRowsBulk({
                tableId,
                numRows,
                tableKind: table.kind,
                sideEffects: sideEffects.serialize(),
              });

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));
                rows.forEach((row) => {
                  table.rows.push({
                    ...row,
                    cells: defaultFormulas.map((formula) => ({
                      isCustomFormula: false,
                      formula: formula ?? "",
                      value: null,
                    })),
                  });
                });
              });

              // Register new rows with engine
              const rowIndex = table.rows.length;
              engine.addRows(sheetId, [rowIndex, numRows]);
              engine.setCellContents(
                { sheet: sheetId, col: 0, row: rowIndex },
                new Array(numRows).fill(defaultFormulas),
              );

              return rows.map((row) => row.id);
            },
          });
        },

        /**
         * Sets the formula for a cell at a specified address.
         * @param address
         * @param newFormula
         */
        setCellFormula: async (address, newFormula) => {
          const sideEffects = new SideEffects(engine, get, set);

          await enqueueAction({
            onEnqueue: () => {
              // Parse cell address
              const { tableId, columnId, rowId } = address;
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));
              const colIndex = table.columns.findIndex(
                ({ id }) => id === columnId,
              );
              const rowIndex = table.rows.findIndex(({ id }) => id === rowId);

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));
                const cell = table.rows[rowIndex].cells[colIndex];
                cell.isCustomFormula = true;
                cell.formula = newFormula;
              });

              // Trigger engine to recalculate cell values
              engine.setCellContents(
                { sheet: sheetId, col: colIndex, row: rowIndex },
                newFormula,
              );

              // Apply side-effects
              sideEffects.init();
              sideEffects.applyOnStoreState();
            },

            onDequeue: () => {
              const { tableId } = address;
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));

              return DjangoClient.updateCellFormula({
                address,
                formula: newFormula,
                tableKind: table.kind,
                sideEffects: sideEffects.serialize(),
              });
            },
          });
        },

        deleteTable: ({ tableId }) => {
          const sideEffects = new SideEffects(engine, get, set);
          const table = get().tables.find((table) => table.id === tableId);

          return enqueueAction({
            onEnqueue: () => {
              // Parse table ID
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));

              // Update store state
              set((state) => {
                state.tables = state.tables.filter(
                  (table) => table.id !== tableId,
                );
                state.selectedTableId = first(state.tables)?.id;
                state.selectedRowIds = [];
              });

              // Notify engine of the removed table and recalculate stale
              // row identifiers
              engine.removeSheet(sheetId);
              recalculateCellValues(engine, (_address, _formula, value) => {
                return (
                  value instanceof RowIdentifier && value.tableKey === table.key
                );
              });

              // Apply side-effects
              sideEffects.init();
              sideEffects.applyOnStoreState();
            },

            onDequeue: () => {
              // Persist changes to server
              assert(isDefined(table));
              return DjangoClient.deleteTable({
                tableKind: table.kind,
                tableId: table.id,
                sideEffects: sideEffects.serialize(),
              });
            },
          });
        },

        deleteDataTableColumn: ({ tableId, columnId }) => {
          const sideEffects = new SideEffects(engine, get, set);

          return enqueueAction({
            onEnqueue: () => {
              // Parse table ID
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));
              const columnIndex = table.columns.findIndex(
                (column) => column.id === columnId,
              );

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));
                table.columns = table.columns.filter(
                  (column) => column.id !== columnId,
                );
                table.rows.forEach((row) => {
                  row.cells.splice(columnIndex, 1);
                });
              });

              // Notify engine of the removed column
              engine.removeColumns(sheetId, [columnIndex, 1]);

              // Apply side-effects
              sideEffects.init();
              sideEffects.applyOnStoreState();
            },

            onDequeue: () => {
              // Persist changes to server
              return DjangoClient.deleteDataTableColumn({
                columnId,
                sideEffects: sideEffects.serialize(),
              });
            },
          });
        },

        deleteRows: ({ tableId, rowIds }) => {
          const sideEffects = new SideEffects(engine, get, set);

          return enqueueAction({
            onEnqueue: () => {
              // Parse table ID
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));
              const rowIndices = rowIds.map((rowId) => {
                return table.rows.findIndex((row) => row.id === rowId);
              });

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));
                table.rows = table.rows.filter(
                  (row) => !rowIds.includes(row.id),
                );
              });

              // Notify engine of removed rows
              engine.removeRows(
                sheetId,
                ...rowIndices.map(
                  (rowIndex) => [rowIndex, 1] satisfies [number, number],
                ),
              );

              // Apply side-effects
              sideEffects.init();
              sideEffects.applyOnStoreState();
            },

            onDequeue: () => {
              // Persist changes to server
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              return DjangoClient.deleteRows({
                tableKind: table.kind,
                rows: rowIds.map((id) => ({
                  id,
                  sideEffects: sideEffects.serialize(),
                })),
              });
            },
          });
        },

        renameColumn: ({ tableId, columnId, newName }) => {
          const sideEffects = new SideEffects(engine, get, set);

          return enqueueAction({
            onEnqueue: () => {
              // Update store state
              set((state) => {
                const table = state.tables.find(
                  (table) => table.id === tableId,
                );
                const column = table?.columns.find(
                  (column) => column.id === columnId,
                );

                if (isDefined(column)) {
                  column.name = newName;
                }
              });
            },

            onDequeue: async () => {
              // Persist changes to server
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              await DjangoClient.updateColumn({
                tableKind: table.kind,
                columnId,
                name: newName,
                sideEffects: sideEffects.serialize(),
              });
              return newName;
            },
          });
        },

        resizeColumn: ({ tableId, columnId, newWidth }) => {
          const sideEffects = new SideEffects(engine, get, set);

          return enqueueAction({
            onEnqueue: () => {
              // Update store state
              set((state) => {
                const table = state.tables.find(
                  (table) => table.id === tableId,
                );
                const column = table?.columns.find(
                  (column) => column.id === columnId,
                );

                if (isDefined(column)) {
                  column.width = newWidth;
                }
              });
            },

            onDequeue: async () => {
              // Persist changes to server
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              await DjangoClient.updateColumn({
                tableKind: table.kind,
                columnId,
                width: newWidth,
                sideEffects: sideEffects.serialize(),
              });
              return newWidth;
            },
          });
        },

        /**
         * Gets the identifier for each row where a specified file is a cell
         * value.
         * @param fileId
         * @returns The row identifiers.
         */
        getFileAssignments: (fileId) => {
          const identifiers: RowIdentifier[] = [];

          get().tables.forEach((table) => {
            table.rows.forEach((row) => {
              row.cells.forEach((cell) => {
                const identifier = new RowIdentifier(table.key, row.index);

                if (
                  cell.value instanceof IdeasFile &&
                  cell.value.attrs.id === fileId
                ) {
                  identifiers.push(identifier);
                }

                if (
                  cell.value instanceof Group &&
                  cell.value.cellValues.some(
                    (value) =>
                      value instanceof IdeasFile && value.attrs.id === fileId,
                  )
                ) {
                  identifiers.push(identifier);
                }
              });
            });
          });

          return uniqWith(
            identifiers,
            (a, b) => a.tableKey === b.tableKey && a.rowIndex === b.rowIndex,
          );
        },

        deleteFile: (fileId, mode) => {
          const dateDeleted = new Date().toISOString();
          const sideEffects = new SideEffects(engine, get, set);

          return enqueueAction({
            onEnqueue: () => {
              // Cancel upload if in-progress
              cancelFileUploads([fileId]);

              // Update store state
              set((state) => {
                state.fileMap[fileId] = new Error("File not found");
              });

              // Apply side-effects
              sideEffects.init();
              const updatedCells = sideEffects.deleteFile(fileId);
              sideEffects.applyOnStoreState();

              // Notify engine of cells with changed formulas
              updatedCells.forEach((cell) => {
                engine.setCellContents(cell.address, cell.newFormula);
              });

              // Recalculate stale formulas referencing the deleted file
              recalculateCellValues(engine, (address, formula) => {
                // If the cell does not contain a formula, do nothing
                if (formula === undefined) {
                  return false;
                }

                let shouldRecalculate = false;
                const { ast } = engine.formulaToAst(formula, address.sheet);

                traverseAst(ast, (node) => {
                  if (
                    node.type === AstNodeType.FUNCTION_CALL &&
                    node.procedureName === "FILE" &&
                    node.args[0].type === AstNodeType.STRING &&
                    node.args[0].value === fileId
                  ) {
                    shouldRecalculate = true;
                  }
                });

                return shouldRecalculate;
              });
            },

            onDequeue: () => {
              return DjangoClient.deleteFile({
                fileId,
                dateDeleted,
                mode,
                sideEffects: sideEffects.serialize(),
              });
            },
          });
        },

        moveColumn: ({ tableId, columnId, newPosition }) => {
          const sideEffects = new SideEffects(engine, get, set);

          return enqueueAction({
            onEnqueue: () => {
              // Calculate new column order
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));
              const oldPosition = table.columns.findIndex(
                ({ id }) => id === columnId,
              );

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));

                // Reorder all columns
                const [column] = table.columns.splice(oldPosition, 1);
                table.columns.splice(newPosition, 0, column);
                table.columns.forEach((column, idx) => (column.order = idx));

                // Reorder cells in each row
                table.rows.forEach((row) => {
                  const [cell] = row.cells.splice(oldPosition, 1);
                  row.cells.splice(newPosition, 0, cell);
                });
              });

              // Notify engine to move column
              if (newPosition < oldPosition) {
                engine.moveColumns(sheetId, oldPosition, 1, newPosition);
              } else {
                engine.moveColumns(sheetId, oldPosition, 1, newPosition + 1);
              }

              // // Apply side-effects
              sideEffects.init();
              sideEffects.applyOnStoreState();
            },

            onDequeue: async () => {
              // Persist changes to server
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              await DjangoClient.reorderColumns({
                tableId: table.id,
                tableKind: table.kind,
                columnIds: table.columns.map((column) => column.id),
                sideEffects: sideEffects.serialize(),
              });
              return columnId;
            },
          });
        },

        pinColumn: ({ tableId, columnId }) => {
          const sideEffects = new SideEffects(engine, get, set);

          return enqueueAction({
            onEnqueue: () => {
              // Calculate column's new position
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));
              const oldPosition = table.columns.findIndex(
                ({ id }) => id === columnId,
              );
              const newPosition = table.columns.filter((c) => c.pinned).length;

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));

                // Reorder all columns
                const [column] = table.columns.splice(oldPosition, 1);
                table.columns.splice(newPosition, 0, column);
                table.columns.forEach((column, idx) => (column.order = idx));

                // Reorder cells in each row
                table.rows.forEach((row) => {
                  const [cell] = row.cells.splice(oldPosition, 1);
                  row.cells.splice(newPosition, 0, cell);
                });

                // Mark column as pinned
                column.pinned = true;
              });

              // Notify engine to move column
              if (newPosition < oldPosition) {
                engine.moveColumns(sheetId, oldPosition, 1, newPosition);
              } else if (newPosition > oldPosition) {
                engine.moveColumns(sheetId, oldPosition, 1, newPosition + 1);
              }

              // Apply side-effects
              sideEffects.init();
              sideEffects.applyOnStoreState();
            },

            onDequeue: async () => {
              // Persist changes to server
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              await DjangoClient.pinColumn({
                tableKind: table.kind,
                columnId,
                sideEffects: sideEffects.serialize(),
              });
              return columnId;
            },
          });
        },

        unpinColumn: ({ tableId, columnId }) => {
          const sideEffects = new SideEffects(engine, get, set);

          return enqueueAction({
            onEnqueue: () => {
              // Calculate column's new position
              const table = get().tables.find(({ id }) => id === tableId);
              assert(isDefined(table));
              const sheetId = engine.getSheetId(table.key);
              assert(isDefined(sheetId));
              const oldPosition = table.columns.findIndex(
                ({ id }) => id === columnId,
              );
              const newPosition =
                table.columns.filter((c) => c.pinned).length - 1;

              // Update store state
              set((state) => {
                const table = state.tables.find(({ id }) => id === tableId);
                assert(isDefined(table));

                // Reorder all columns
                const [column] = table.columns.splice(oldPosition, 1);
                table.columns.splice(newPosition, 0, column);
                table.columns.forEach((column, idx) => (column.order = idx));

                // Reorder cells in each row
                table.rows.forEach((row) => {
                  const [cell] = row.cells.splice(oldPosition, 1);
                  row.cells.splice(newPosition, 0, cell);
                });

                // Mark column as unpinned
                column.pinned = false;
              });

              // Notify engine to move column
              if (newPosition < oldPosition) {
                engine.moveColumns(sheetId, oldPosition, 1, newPosition);
              } else if (newPosition > oldPosition) {
                engine.moveColumns(sheetId, oldPosition, 1, newPosition + 1);
              }

              // Apply side-effects
              sideEffects.init();
              sideEffects.applyOnStoreState();
            },

            onDequeue: async () => {
              // Persist changes to server
              const table = get().tables.find((table) => table.id === tableId);
              assert(isDefined(table));
              await DjangoClient.unpinColumn({
                tableKind: table.kind,
                columnId,
                sideEffects: sideEffects.serialize(),
              });
              return columnId;
            },
          });
        },

        uploadFiles: ({ blobs, address }) => {
          return enqueueAction({
            // We can't update state optimistically for this action
            onEnqueue: () => undefined,

            onDequeue: async () => {
              // Create file objects
              const newFiles = await Promise.all(
                blobs.map(async (blob) => {
                  const { fileType, fileFormat } = await identifyFile(blob);
                  const processingStatus =
                    fileType === FILE_TYPES_BY_KEY["unknown"].id
                      ? // File type is unknown meaning it's not processable so we tell the backend to skip processing
                        ProcessingStatus["SKIPPED"]
                      : ProcessingStatus["PENDING"];

                  return DjangoClient.createFile({
                    name: blob.name,
                    projectId: project.id,
                    size: blob.size,
                    tenantId,
                    fileType,
                    fileFormat,
                    processingStatus,
                  });
                }),
              );

              // Queue uploads
              newFiles.forEach((newFile, idx) => {
                enqueueFile({
                  drsFile: {
                    id: newFile.id,
                    partSize: newFile.part_size,
                  },
                  project: {
                    id: project.id,
                    name: project.name,
                    key: projectKey,
                    tenantId,
                  },
                  blob: blobs[idx],
                  tenantId,
                });
              });

              // Add files to cell formula
              const fileIds = newFiles.map(({ id }) => id);
              const hfAddress = convertIdeasCellAddress(address);
              const sideEffects = new SideEffects(engine, get, set);
              const newFormula = sideEffects.addFiles(fileIds, hfAddress);
              void get().setCellFormula(address, newFormula ?? "");

              return fileIds;
            },
          });
        },
      } satisfies DataTableStoreState;
    }),
  );
};
