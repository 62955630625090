import {
  CellValue,
  DetailedCellError,
  Group,
  IdeasFile,
  RoiFrame,
  RowIdentifier,
} from "@inscopix/ideas-hyperformula";
import { DataTableColumnDefinition } from "../store/DataTableProvider.types";
import { CellErrorLoading } from "../store/engine";
import { isInteger } from "lodash";
import { FILE_TYPES_BY_ID } from "types/FileTypes";

type GetValidationErrorsOptions = {
  columnDefinition: DataTableColumnDefinition;
  isColumnRequired: boolean;
  value: CellValue;
};

/**
 * Get an array of errors describing why a cell value is invalid in a specified
 * column.
 * @param options
 * @returns An array of validation errors.
 */
export const getValidationErrors = (options: GetValidationErrorsOptions) => {
  const { columnDefinition, isColumnRequired, value } = options;
  const isValueLoading =
    value instanceof DetailedCellError &&
    value.message === CellErrorLoading.message;
  const isEmptyValue = value === null || value === "";

  const errors: string[] = [];

  // Do not display validation errors while async values are loading
  if (isValueLoading) {
    return [];
  }

  // Validate value is non-empty if column is required
  if (isEmptyValue && isColumnRequired) {
    errors.push("Value is required");
  }

  // Validate boolean columns
  if (columnDefinition.kind === "boolean" && !isEmptyValue) {
    if (typeof value !== "boolean") {
      errors.push('Value must be of type "boolean"');
    }
  }

  // Validate choice columns
  if (columnDefinition.kind === "choice" && !isEmptyValue) {
    const isValidValue = columnDefinition.choices.some(
      (choice) => choice.value === value,
    );
    if (!isValidValue) {
      const choices = columnDefinition.choices
        .map(({ value }) => value)
        .join(", ");
      errors.push(`Value must be one of: ${choices}`);
    }
  }

  // Validate file columns
  if (columnDefinition.kind === "file" && !isEmptyValue) {
    const fileType = FILE_TYPES_BY_ID[columnDefinition.file_type];
    const isFile = value instanceof IdeasFile;
    const isGroupOfFiles =
      value instanceof Group &&
      value.cellValues.every((value) => value instanceof IdeasFile);

    if (!isFile && !isGroupOfFiles) {
      errors.push('Value must be of type "file"');
    }

    if (
      fileType.key !== "unknown" &&
      value instanceof IdeasFile &&
      value.attrs.fileType !== null &&
      value.attrs.fileType !== fileType.id
    ) {
      errors.push(`File must be of type "${fileType.name}"`);
    }

    if (
      fileType.key !== "unknown" &&
      value instanceof Group &&
      value.cellValues.some(
        (file) =>
          file instanceof IdeasFile &&
          file.attrs.fileType !== null &&
          file.attrs.fileType !== fileType.id,
      )
    ) {
      errors.push(`All files must be of type "${fileType.name}"`);
    }
  }

  // Validate float columns
  if (columnDefinition.kind === "float" && !isEmptyValue) {
    const { min, max } = columnDefinition;

    if (typeof value !== "number" || isNaN(parseFloat(value.toString()))) {
      errors.push('Value must be of type "float"');
    }

    if (min !== null && typeof value === "number" && value < min) {
      errors.push(`Value must be greater than or equal to ${min}`);
    }

    if (max !== null && typeof value === "number" && value > max) {
      errors.push(`Value must be less than or equal to ${max}`);
    }
  }

  // Validate identifier columns
  if (columnDefinition.kind === "identifier" && !isEmptyValue) {
    if (!(value instanceof RowIdentifier)) {
      errors.push('Value must be of type "row identifier"');
    }
  }

  // Validate integer columns
  if (columnDefinition.kind === "integer" && !isEmptyValue) {
    const { min, max } = columnDefinition;

    if (typeof value !== "number" || !isInteger(value)) {
      errors.push('Value must be of type "integer"');
    }

    if (min !== null && typeof value === "number" && value < min) {
      errors.push(`Value must be greater than or equal to ${min}`);
    }

    if (max !== null && typeof value === "number" && value > max) {
      errors.push(`Value must be less than or equal to ${max}`);
    }
  }

  // Validate ROI frame columns
  if (columnDefinition.kind === "roi_frame" && !isEmptyValue) {
    if (!(value instanceof RoiFrame)) {
      errors.push('Value must be of type "ROI frame"');
    }
  }

  // Validate text columns
  if (columnDefinition.kind === "text" && !isEmptyValue) {
    if (!["boolean", "number", "string"].includes(typeof value)) {
      errors.push('Value must be of type "text"');
    }
  }

  return errors;
};
