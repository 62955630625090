import { EuiButtonIcon, EuiButtonIconProps } from "@inscopix/ideas-eui";
import { File as DrsFile } from "graphql/_Types";
import { useModalContext } from "providers/ModalProvider/ModalProvider";
import { Tooltip } from "../Tooltip/Tooltip";
import { memo } from "react";
import { ModalTaskLogsFileProcessing } from "components/NotificationsFeed/alerts/ModalTaskLogsFileProcessing";

export interface ButtonViewTaskLogsDrsFileProps
  extends Pick<EuiButtonIconProps, "color"> {
  drsFileId: DrsFile["id"];
}

export const ButtonViewTaskLogsDrsFile = memo(
  function ButtonViewTaskLogsDrsFile({
    drsFileId,
    ...buttonIconProps
  }: ButtonViewTaskLogsDrsFileProps) {
    const { openModal, closeModal } = useModalContext();
    const showModal = () =>
      openModal(
        <ModalTaskLogsFileProcessing fileId={drsFileId} onClose={closeModal} />,
      );

    return (
      <>
        <Tooltip content={"toolTip"}>
          <EuiButtonIcon
            aria-label="View task logs"
            iconType="tableDensityExpanded"
            onClick={showModal}
            {...buttonIconProps}
          />
        </Tooltip>
      </>
    );
  },
);
