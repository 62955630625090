import { CellValue } from "@inscopix/ideas-hyperformula";
import { CellEditorFileUpload } from "./CellEditorFileUpload";
import { useEffect } from "react";

interface CellEditorFileProps {
  tableId: string;
  tableKind: "data" | "analysis";
  columnId: string;
  rowId: string;
  value: CellValue;
  onChangeFormula: (newFormula: string) => void;
  stopEditing: (cancel?: boolean) => void;
}

/** Cell editor for cells in file columns */
export const CellEditorFile = ({
  tableId,
  tableKind,
  columnId,
  rowId,
  value,
  onChangeFormula,
  stopEditing,
}: CellEditorFileProps) => {
  // TODO: Implement after data source columns are supported
  useEffect(() => {
    if (tableKind === "analysis") {
      stopEditing(true);
    }
  }, [stopEditing, tableKind]);

  return (
    <CellEditorFileUpload
      tableId={tableId}
      columnId={columnId}
      rowId={rowId}
      value={value}
      stopEditing={stopEditing}
    />
  );
};
