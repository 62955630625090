import { Project } from "../../graphql/_Types";
import { isDefined } from "utils/isDefined";
import { ModalConfirmDestructiveAction } from "components/ModalConfirmDestructiveAction/ModalConfirmDestructiveAction";
import { captureException } from "@sentry/react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "django/queryKeys";
import { projectScheduleDeleteDjango } from "hooks/useProjectSheduleDeleteDjango/useProjectSheduleDeleteDjango";

type ModalDeleteProjectProps = {
  projectId: Project["id"];
  onClose?: () => void;
  deleteProject: typeof projectScheduleDeleteDjango;
};

function ModalDeleteProject({
  projectId,
  onClose,
  deleteProject: deleteProjectFn,
}: ModalDeleteProjectProps) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    if (isDefined(onClose)) {
      onClose();
    }
  };

  const deleteProject = async () => {
    setLoading(true);
    try {
      await deleteProjectFn(projectId, {
        date_deleted: new Date().toISOString(),
      });

      await queryClient.invalidateQueries({
        queryKey: queryKeys.getAllProjects("internal"),
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.getAdminProjects,
      });
      addUtilityToastSuccess("Successfully deleted project");
    } catch (err) {
      captureException(err);
      addUtilityToastFailure("Failed to delete Project");
    }
    setLoading(false);
  };

  const handleConfirm = async () => {
    await deleteProject();
    closeModal();
  };

  return (
    <ModalConfirmDestructiveAction
      title="Delete Project"
      onCancel={closeModal}
      onConfirm={(e) => {
        e.preventDefault();
        void handleConfirm();
      }}
      confirmButtonText="Delete Project"
      isLoading={loading}
      confirmCheckboxPrompt="I want to permanently delete this project and all associated data"
      body={
        <p>
          You are about to delete a project permanently along with all its data
          - this operation cannot be undone.
        </p>
      }
    />
  );
}

export default ModalDeleteProject;
